<template>
  <div class="classHour-wrap">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="科目">
              <a-select allowClear placeholder="请选择" v-model="searchSubject" @change="initSearchData('search')">
                <a-select-option v-for="item in subjectArr" :key="item.value" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="类别">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                v-model="searchCuType"
                @change="initSearchData('search')"
              >
                <a-select-option v-for="item in optionsArr.cuTypes" :key="item.value" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="来源">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="searchSource">
                <a-select-option :value="item.value" v-for="(item, index) in optionsArr.sources" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="initPagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ y: 600 }"
      >
        <span slot="subject" slot-scope="text">{{ getDdlLabel(subjectArr, text) }}</span>
        <span slot="whoCreated" slot-scope="text">{{ extractOperator(text) }}</span>
        <span slot="whenCreated" slot-scope="text">{{ $moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
        <span slot="description" slot-scope="text, record">
          <span v-if="record.typeDisplay === '系统修复扣减'" style="color: red">(系统修复)</span>
          {{ text }}
        </span>
        <span slot="remark" slot-scope="remark, record">
          <a-tooltip placement="left">
            <template slot="title">
              <span> {{ remark }} </span>
            </template>
            <span> {{ remark }} </span>
          </a-tooltip>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import { queryClassFlow, queryScreenOption } from '@/api/headTeacher';
import { getDdlLabel, extractOperator } from '@/utils/util';

const roles = storage.get('roles');

export default {
  data() {
    return {
      optionsArr: {},

      searchSource: undefined,
      searchCuType: undefined,
      searchSubject: undefined,

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '操作员姓名',
          dataIndex: 'whoCreated',
          key: 'whoCreated',
          scopedSlots: { customRender: 'whoCreated' },
        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '类别',
          dataIndex: 'cuTypeDisplay',
          key: 'cuTypeDisplay',
        },
        {
          title: '说明',
          key: 'description',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
        },
        {
          title: '日期',
          key: 'whenCreated',
          dataIndex: 'whenCreated',
          scopedSlots: { customRender: 'whenCreated' },
        },
        {
          title: '数量',
          key: 'amount',
          dataIndex: 'amount',
        },
        {
          title: '备注',
          key: 'remark',
          dataIndex: 'remark',
          width: 250,
          ellipsis: true,
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '来源',
          key: 'source',
          dataIndex: 'source',
        },
        {
          title: '来源信息',
          key: 'typeDisplay',
          dataIndex: 'typeDisplay',
        },
      ],
      getDdlLabel,
      extractOperator,
      roles,

      pageNum: 1,
      pageSize: 10,
      current: 1,
      data: [],
      loading: false,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      urlContainsSearch: false,
    };
  },
  created() {},
  methods: {
    resetCondition() {
      this.searchSubject = undefined;
      this.searchCuType = undefined;
      this.searchSource = undefined;
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData('search');
    },
    handleTableChange(data) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initSearchData();
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }

      this.loading = true;
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        subject: this.searchSubject,
        cuType: this.searchCuType,
        source: this.searchSource,
        studentId: this.$route.query.id,
      };
      queryClassFlow(params).then((res) => {
        this.data = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.loading = false;
      });
    },
    // 获取下来项
    queryScreenOption() {
      queryScreenOption().then((res) => {
        this.optionsArr = res.data.content;
      });
    },
  },
  mounted() {
    this.queryScreenOption();
    this.initSearchData();
  },
};
</script>
<style lang="less" scoped>
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 120px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 80%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
</style>
